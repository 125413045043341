import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { motion } from "framer-motion"
import slugify from "slugify"

import FlipImage from "../components/FlipImage"

import { formatPrice } from "../utils/formatPrice"
import Seo from "../components/Seo"

const ProductTypeTemplate = ({ pageContext, data }) => {
  const slugifiedNavItem = slugify(pageContext.navItem, {
    lower: true,
    locale: "de",
  })

  const slugifiedProductType = slugify(pageContext.productType, {
    lower: true,
    locale: "de",
  })

  return (
    <>
      <Seo
        title={`${pageContext?.productType} - ${process.env.GATSBY_SHOP_OWNER} Onlineshop`}
        description={`Alle Produkte vom Typ ${pageContext?.productType} im ${process.env.GATSBY_SHOP_OWNER} Onlineshop`}
      />
      <BreadcrumbWrapper>
        <Breadcrumb to="/shop">Shop</Breadcrumb>
        <span className="breadcrumb-divider">|</span>
        <Breadcrumb to={`/shop/${slugifiedNavItem}`}>
          {pageContext.navItem}
        </Breadcrumb>
        <span className="breadcrumb-divider">|</span>
        <Location>{pageContext.productType}</Location>
      </BreadcrumbWrapper>

      <h1 style={{ textAlign: "center", margin: "3rem 0" }}>
        {pageContext?.productType}
      </h1>
      <ProductTypeGrid>
        {data.products.nodes.map((product, i) => {
          const { id, title, priceRangeV2 } = product

          const price = formatPrice(
            priceRangeV2.minVariantPrice.currencyCode,
            priceRangeV2.minVariantPrice.amount
          )

          return (
            <motion.div
              key={id}
              initial={{ opacity: 0, translateY: 50 }}
              animate={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.6, delay: i * 0.1 }}
            >
              <ProductTypeCard>
                <Link
                  to={`/shop/${slugifiedNavItem}/${slugifiedProductType}/${product.handle}`}
                >
                  <FlipImage media={product.media} title={product.title} />
                  <span>{title}</span>
                  <Price>{price}</Price>
                </Link>
              </ProductTypeCard>
            </motion.div>
          )
        })}
      </ProductTypeGrid>
    </>
  )
}

export const query = graphql`
  query ProductTypes(
    $navItem: String!
    $productType: String
    $vendor: String!
  ) {
    products: allShopifyProduct(
      filter: {
        metafields: { elemMatch: { value: { eq: $navItem } } }
        vendor: { eq: $vendor }
        productType: { eq: $productType }
      }
    ) {
      nodes {
        id
        title
        handle
        productType
        vendor
        featuredImage {
          gatsbyImageData(
            aspectRatio: 1
            placeholder: TRACED_SVG
            width: 300
            height: 300
          )
        }
        priceRangeV2 {
          minVariantPrice {
            amount
            currencyCode
          }
        }
        media {
          ... on ShopifyMediaImage {
            image {
              gatsbyImageData(aspectRatio: 1, width: 300, height: 300)
            }
          }
        }
      }
    }
  }
`

const ProductTypeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 250px));
  justify-content: center;
  margin: auto;
  gap: 3rem 1rem;
  margin-bottom: 4rem;
  padding: 0 1rem;

  .productTypeCard {
    a {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.text};
      font-family: ${({ theme }) => theme.fontHeading};
      font-size: 1.5rem;
    }

    span {
      display: block;
      text-align: center;
      margin-top: 1rem;
    }
  }
`

const ProductTypeCard = styled.div`
  text-align: center;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fontHeading};
    font-size: 1.5rem;
  }

  span {
    display: block;
    text-align: center;
    margin-top: 1rem;
  }

  p {
    text-align: center;
    font-size: 1rem;
  }
`

const Price = styled.p`
  display: inline-block;
  padding: 0.5rem 1rem;
  background: white;
  text-align: center;
  font-weight: 400;
  filter: ${({ theme }) => theme.dropShadow};
  transform: skew(-8deg);
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
`

const BreadcrumbWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.textLight};
  font-size: 0.9rem;
  margin: 2rem 2rem 0;
  white-space: nowrap;
  overflow-x: auto;
  padding: 0.5rem 0;
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none;
  }

  a {
    /* margin-right: 0.5rem; */
    color: ${({ theme }) => theme.colors.textLight};

    :hover {
      /* text-decoration: underline; */
    }
  }

  span {
    margin-right: 0.5rem;
  }

  .breadcrumb-divider {
    display: inline-block;
    margin: 0 0.5rem;
  }

  @media (max-width: 500px) {
    margin-bottom: 1rem;
  }
`

const Breadcrumb = styled(Link)`
  display: inline-flex;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text};
`

const Location = styled.span`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.text};
`

export default ProductTypeTemplate
